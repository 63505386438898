import React from 'react';
import { PostMetadataProps } from '../../lib/sanity-types/post';
import MaxWidthContainer from '../core/maxWidthContainer';
import PortableText from '../core/portableText';
import SectionContainer from '../section/sectionContainer';

const Post = (props: PostMetadataProps) => {
  const formattedPublishDate = new Date(props.publishDate).toLocaleDateString('de-DE');

  return (
    <MaxWidthContainer>
      <SectionContainer>
        <div className="mt-10 lg:mt-14 pb-56">
          <div className="max-w-2xl mx-auto text-center">
            <div className="mt-2">
              <span className="text-blue-aqua font-universBoldCondensed text-lg">{props.category.title}</span>
              <h2 className="text-titleSmallMobile sm:text-titleSmallTablet lg:text-titleSmall font-universBoldCondensed mb-6">
                {props.title}
              </h2>
              <p className="text-lg text-center text-gray-600">{props.excerpt}</p>
              <div className="flex flex-col justify-center border-t-2 border-b-2 border-gray-200 mt-6 mb-12 py-4">
                <div className="text-base text-blue-aqua font-universBoldCondensed">Autor</div>
                <div className="text-gray-600">{props.author}</div>
                <div className="text-small text-gray-600">{formattedPublishDate}</div>
              </div>
            </div>
          </div>
          <div className="max-w-2xl mx-auto text-base">
            <PortableText blocks={props._rawBody}></PortableText>
          </div>
        </div>
      </SectionContainer>
    </MaxWidthContainer>
  );
};

export default Post;
