import { graphql } from 'gatsby';
import React from 'react';
import BlogPost from '../components/blog/post';
import Layout from '../components/core/layout';
import { createFooterProps, mapEdgesToNodes } from '../lib/helpers';
import { PostMetadataProps } from '../lib/sanity-types/post';

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      publishDate
      category {
        title
      }
      mainImage {
        ...SanityImage
        altText
      }
      title
      slug {
        current
      }
      author
      excerpt
      seo {
        title
        description
        keywords
        ogImage {
          asset {
            altText
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          }
        }
      }
      _rawBody(resolveReferences: { maxDepth: 5 })
    }

    siteConfig: allSanitySiteConfiguration {
      edges {
        node {
          id
          title
          mainImage {
            ...SanityImage
            altText
          }
          mainNavigation {
            id
            url
            navigationMenuName
          }
          footerTagline
          footerSocialMedia {
            id
            link
            name
            icon
          }
          footerProductLinks {
            id
            navigationMenuName
            url
          }
          footerGeneralLinks {
            id
            navigationMenuName
            url
          }
          footerCompanyLinks {
            id
            navigationMenuName
            url
          }
        }
      }
    }
  }
`;

const BlogPostPageTemplate = ({ data }: any) => {
  const post = data.post as PostMetadataProps;
  const siteConfig = data && data.siteConfig && mapEdgesToNodes(data.siteConfig)[0];
  const footerProps = createFooterProps(siteConfig);

  return (
    <Layout
      seoMetadata={post.seo}
      navigationItems={siteConfig.mainNavigation}
      mainImage={siteConfig.mainImage}
      footerProps={footerProps}>
      <BlogPost {...post}></BlogPost>
    </Layout>
  );
};

export default BlogPostPageTemplate;
